export default [
  {
    name: "Gospel Glider Gun",
    data: [[-6,4],[-5,3],[-5,2],[-5,1],[-4,2],[-6,0],[-7,2],[-8,5],[-9,5],[-8,-1],[-9,-1],[-10,4],[-10,0],[-11,1],[-11,2],[-11,3],[-1,3],[0,3],[-1,4],[0,4],[-1,5],[0,5],[1,6],[1,2],[3,6],[3,7],[3,2],[3,1],[13,5],[13,4],[14,4],[14,5],[-20,3],[-21,3],[-21,2],[-20,2]]
  },
  {
    name: "Glider",
    data: [
      [0, 2],[1, 0],[1, 2],[2, 1],[2, 2]
    ]
  },
  {
    name: "Lightweight spaceship (LWSS)",
    data: [[0,-1],[-1,-1],[1,-1],[2,-1],[2,0],[2,1],[-2,0],[-2,2],[1,2]]
  },
  {
    name: "Middleweight spaceship",
    data: [[0,-1],[-1,-1],[1,-1],[2,-1],[2,0],[2,1],[-2,-1],[1,2],[-1,3],[-3,2],[-3,0]]
  },
  {
    name: "Heavyweight spaceship",
    data: [[0,-1],[-1,-1],[1,-1],[2,-1],[2,0],[2,1],[-2,-1],[1,2],[-1,3],[-3,-1],[-4,0],[-4,2],[-2,3]]
  },
  {
    name: "???",
    data: [
      [-3, -1],
      [-4, -1],
      [-1, -2],
      [-1, -3],
      [-1, -4],
      [-3, -6],
      [-4, -6],
      [-6, -2],
      [-6, -3],
      [-6, -4],
      [2, -1],
      [3, -1],
      [4, -1],
      [1, -2],
      [1, -3],
      [2, -6],
      [3, -6],
      [4, -6],
      [6, -2],
      [6, -3],
      [6, -4],
      [-2, 1],
      [-3, 1],
      [-1, 2],
      [-1, 3],
      [-1, 4],
      [-2, 6],
      [-3, 6],
      [-4, 6],
      [-6, 2],
      [-6, 3],
      [-6, 4],
      [2, 1],
      [3, 1],
      [4, 1],
      [1, 2],
      [1, 3],
      [1, 4],
      [2, 6],
      [3, 6],
      [4, 6],
      [6, 2],
      [6, 3],
      [6, 4],
      [0, 0],
      [-3, 4],
      [-3, 3],
      [-4, 3],
      [3, 4],
      [3, 3],
      [4, 3],
      [-4, -3],
      [-3, -3],
      [-3, -4],
      [3, -4],
      [3, -3],
      [0, -6],
      [-6, -6],
      [6, -6],
      [6, 0],
      [-6, 0],
      [-6, 6],
      [6, 6],
      [0, 6],
      [-6, 5],
      [-5, 6],
      [-4, 4],
      [-6, 1],
      [-6, -1],
      [-6, -5],
      [-5, -6],
      [-4, -4],
      [-4, -2],
      [-3, -2],
      [-2, -2],
      [-2, -3],
      [-2, -4],
      [-4, 0],
      [-4, 1],
      [-4, 2],
      [-1, 6],
      [1, 6],
      [5, 6],
      [6, 1],
      [6, -1],
      [6, -5],
      [1, -6],
      [-1, -6],
      [-2, -6],
      [0, -4],
      [0, -3],
      [0, -2],
      [0, -1],
      [-3, 0],
      [-2, 0],
      [-2, -1],
      [-1, -1],
      [-1, 0],
      [-1, 1],
      [-3, 2],
      [-2, 2],
      [-2, 3],
      [-2, 4],
      [0, 4],
      [0, 3],
      [0, 2],
      [0, 1],
      [1, 1],
      [1, 0],
      [1, -1],
      [2, 0],
      [4, 0],
      [3, 0],
      [4, 2],
      [3, 2],
      [2, 2],
      [2, 3],
      [2, 4],
      [4, 4],
      [5, -6],
      [2, -4],
      [2, -3],
      [2, -2],
      [3, -2],
      [4, -2],
      [4, -3],
      [4, -4],
      [6, 5],
      [1, -4]
    ]
  },
  {
    name: "Pulsar",
    data: [
      [-2, -1],
      [-3, -1],
      [-4, -1],
      [-1, -2],
      [-1, -3],
      [-1, -4],
      [-2, -6],
      [-3, -6],
      [-4, -6],
      [-6, -2],
      [-6, -3],
      [-6, -4],
      [2, -1],
      [3, -1],
      [4, -1],
      [1, -2],
      [1, -3],
      [1, -4],
      [2, -6],
      [3, -6],
      [4, -6],
      [6, -2],
      [6, -3],
      [6, -4],
      [-2, 1],
      [-3, 1],
      [-4, 1],
      [-1, 2],
      [-1, 3],
      [-1, 4],
      [-2, 6],
      [-3, 6],
      [-4, 6],
      [-6, 2],
      [-6, 3],
      [-6, 4],
      [2, 1],
      [3, 1],
      [4, 1],
      [1, 2],
      [1, 3],
      [1, 4],
      [2, 6],
      [3, 6],
      [4, 6],
      [6, 2],
      [6, 3],
      [6, 4]
    ]
  },
  {
    name: "Lines",
    data: [
      [-5, -5],
      [-4, -5],
      [-3, -5],
      [-2, -5],
      [-1, -5],
      [0, -5],
      [1, -5],
      [2, -5],
      [3, -5],
      [4, -5],
      [5, -5],
      [-5, 0],
      [-4, 0],
      [-3, 0],
      [-2, 0],
      [-1, 0],
      [0, 0],
      [1, 0],
      [2, 0],
      [3, 0],
      [4, 0],
      [5, 0],
      [-5, 5],
      [-4, 5],
      [-3, 5],
      [-2, 5],
      [-1, 5],
      [0, 5],
      [1, 5],
      [2, 5],
      [3, 5],
      [4, 5],
      [5, 5],
      [-6, 5],
      [-7, 5],
      [-9, 5],
      [-8, 5],
      [-11, 5],
      [-10, 5],
      [-12, 5],
      [-14, 5],
      [-13, 5],
      [6, 5],
      [7, 5],
      [11, 5],
      [12, 5],
      [8, 5],
      [9, 5],
      [10, 5],
      [13, 5],
      [-6, 0],
      [-10, 0],
      [-14, 0],
      [-13, 0],
      [-12, 0],
      [-11, 0],
      [-9, 0],
      [-8, 0],
      [-7, 0],
      [6, 0],
      [7, 0],
      [8, 0],
      [9, 0],
      [10, 0],
      [13, 0],
      [11, 0],
      [12, 0],
      [13, -5],
      [10, -5],
      [6, -5],
      [7, -5],
      [8, -5],
      [9, -5],
      [11, -5],
      [12, -5],
      [-14, -5],
      [-13, -5],
      [-12, -5],
      [-10, -5],
      [-9, -5],
      [-8, -5],
      [-7, -5],
      [-6, -5],
      [-11, -5]
    ]
  },
  {
    name: "Penta-decathlon",
    data: [
      [-2, 0],
      [-1, 0],
      [0, 0],
      [-2, 1],
      [-1, 1],
      [0, 1],
      [-2, 2],
      [-1, 2],
      [0, 2],
      [-2, -1],
      [-1, -1],
      [0, -1],
      [-2, 3],
      [-1, 4],
      [-2, 4],
      [0, 4],
      [0, 3],
      [-2, -2],
      [-2, -3],
      [-1, -3],
      [0, -3],
      [0, -2]
    ]
  },
  {
    name: "Blinkers",
    data: [
      [0, -5],
      [0, -4],
      [0, -3],
      [0, -1],
      [0, 0],
      [0, 1],
      [0, 3],
      [4, 3],
      [0, 4],
      [4, 4],
      [0, 5],
      [4, 5],
      [4, 1],
      [4, 0],
      [4, -1],
      [4, -4],
      [4, -3],
      [4, -5],
      [-4, -5],
      [-4, -4],
      [-4, -3],
      [-4, -1],
      [-4, 0],
      [-4, 1],
      [-4, 3],
      [-4, 4],
      [-4, 5],
      [0, 7],
      [0, 8],
      [0, 9],
      [4, 7],
      [4, 8],
      [4, 9],
      [-4, 7],
      [-4, 8],
      [-4, 9],
      [-4, -7],
      [-4, -8],
      [-4, -9],
      [0, -9],
      [0, -8],
      [0, -7],
      [4, -9],
      [4, -8],
      [4, -7],
      [8, -7],
      [8, -8],
      [8, -9],
      [8, -5],
      [8, -4],
      [8, -3],
      [8, -1],
      [8, 0],
      [8, 1],
      [8, 3],
      [8, 5],
      [8, 4],
      [8, 7],
      [8, 8],
      [8, 9],
      [-8, 8],
      [-8, 9],
      [-8, 7],
      [-8, 5],
      [-8, 4],
      [-8, 3],
      [-8, 1],
      [-8, 0],
      [-8, -1],
      [-8, -3],
      [-8, -4],
      [-8, -5],
      [-8, -7],
      [-8, -8],
      [-8, -9]
    ]
  },
  {
    name: "Toads",
    data: [
      [-2, 0],[-1, 1],[-1, 0],[0, 1],[2, 4],[3, 4],[4, 5],[3, 5],[3, -3],[4, -3],[3, -4],[2, -4],[-4, 5],[-5, 5],[-5, 4],[-6, 4],[-4, -3],[-5, -3],[-6, -4],[-5, -4],[5, 5],[-3, 5],[-4, 4],[4, 4],[1, 1],[0, 0],[-4, -4],[-3, -3],[4, -4],[5, -3]
    ]
  },
  {
    name: "Beacons (smoochers)",
    data: [
      [-5, 4],
      [-4, 4],
      [3, 4],
      [4, 4],
      [1, 2],
      [-3, -2],
      [1, -2],
      [2, -2],
      [3, -4],
      [4, -4],
      [-5, 5],
      [-4, 5],
      [-3, 2],
      [-3, 3],
      [-2, 3],
      [-2, 2],
      [1, 3],
      [2, 3],
      [3, 5],
      [4, 5],
      [2, 2],
      [-3, -1],
      [-2, -1],
      [-2, -2],
      [-4, -3],
      [-5, -3],
      [-4, -4],
      [-5, -4],
      [1, -1],
      [2, -1],
      [3, -3],
      [4, -3]
    ]
  },
  {
    name: "Block",
    data: [
      [-1, 0],[0, 0],[0, 1],[-1, 1]
    ]
  },
  {
    name: "Beehive",
    data: [
      [0, 1],[-1, 1],[-2, 0],[1, 0],[-1, -1],[0, -1]
    ]
  },
  {
    name: "Loaf",
    data: [
      [0, -2],[-1, -1],[1, -1],[-2, 0],[1, 0],[-1, 1],[0, 1]
    ]
  },
  {
    name: "Boat",
    data: [
      [-1, -1],[-2, 0],[0, 0],[-2, 1],[-1, 1]
    ]
  },
  {
    name: "Tub",
    data: [
      [-1, -1],[-2, 0],[0, 0],[-1, 1]
    ]
  },
  {
    name: "New Thing",
    data: [[-2,7],[1,7],[-3,6],[-1,6],[0,6],[2,6],[-2,5],[1,5],[-2,4],[1,4],[-3,3],[-1,3],[0,3],[2,3],[-2,2],[1,2]]
  },
  {
    name: "Creeper Face",
    data: [
      [-5, 7],
      [-4, 7],
      [-3, 7],
      [-2, 7],
      [0, 7],
      [-1, 7],
      [1, 7],
      [2, 7],
      [-5, 6],
      [-4, 6],
      [-3, 6],
      [-2, 6],
      [-1, 6],
      [1, 6],
      [2, 6],
      [0, 6],
      [-2, 5],
      [-2, 4],
      [-1, 4],
      [-1, 5],
      [-5, 5],
      [-5, 4],
      [2, 5],
      [2, 4],
      [-5, 3],
      [-4, 3],
      [-3, 3],
      [0, 3],
      [1, 3],
      [2, 3],
      [1, 2],
      [-4, 1],
      [-4, 0],
      [-3, -1],
      [-2, -1],
      [-1, -1],
      [0, -1],
      [1, -1],
      [1, 1],
      [1, 0],
      [2, 2],
      [2, 1],
      [2, 0],
      [2, -1],
      [-4, -1],
      [-5, -1],
      [-5, 0],
      [-5, 2],
      [-5, 1],
      [-4, 2],
      [-2, 0],
      [-1, 0]
    ]
  }
];
